<template>
  <table class="table-style c-form">
    <tr>
      <td>Religion</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.social_class?.attributes.religion || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Caste</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.social_class?.attributes.caste || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Sub Caste</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.social_class?.attributes.sub_caste || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Citizenship</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.social_class?.attributes.citizenship || 'NA'
        }}</label>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style scoped>
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
