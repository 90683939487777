<template>
  <div>
    <div class="card-align">
      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card1 p-d-flex">
          <div class="p-col-4">
            <div class="profile">
              <Skeleton height="14rem" width="100%" border-radius="16px" />
            </div>
          </div>
          <div class="p-col-8 p-d-flex p-flex-column p-jc-between">
            <section-loader :hide-header="true" :row-count="7" />
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card1 p-d-flex">
          <section-loader :row-count="7" />
        </div>
      </div>
      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card2 p-d-flex">
          <section-loader :row-count="11" />
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card2 p-d-flex">
          <section-loader :row-count="11" />
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card3 p-d-flex">
          <section-loader :row-count="4" />
        </div>
      </div>
      <div class="p-col-12 p-lg-6 p-md-12 style">
        <div class="card3 p-d-flex">
          <section-loader :row-count="4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLoader from './SectionLoader.vue';
export default {
  components: { SectionLoader },
};
</script>

<style scoped lang="scss">
.card-align {
  display: flex;
  flex-wrap: wrap;
}
.style {
  display: -webkit-flex;
}
.card1 {
  min-height: 18.625rem;
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $borderColor;
  padding: 1rem;
  padding-top: 2.5rem;
  width: 100%;
  display: center;
  margin: 3px;
}
.card2 {
  min-height: 27.5rem;
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $borderColor;
  padding: 1rem;
  padding-top: 2.5rem;
  width: 100%;
  display: center;
  margin: 3px;
}
.card3 {
  min-height: 13.75rem;
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $borderColor;
  padding: 1rem;
  padding-top: 2.5rem;
  width: 100%;
  display: center;
  margin: 3px;
}
</style>
