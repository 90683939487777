<template>
  <table class="table-style c-form">
    <tr>
      <td>Father Name</td>
      <td>
        :
        <label class="p-ml-2 name-align">{{
          profile.family?.attributes?.father || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Mother Name</td>
      <td>
        :
        <label class="p-ml-2 name-align">{{
          profile.family?.attributes?.mother || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Father status</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.family?.attributes?.father_status) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Mother status</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.family?.attributes?.mother_status) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Family Type</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.family?.attributes?.family_type) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>No Of Married Sisters</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.family?.attributes?.no_of_married_sisters || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>No Of Married Brothers</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.family?.attributes?.no_of_married_brothers || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>No Of Unmarried Sisters</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.family?.attributes?.no_of_unmarried_sisters || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>No Of Unmarried Brothers</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.family?.attributes?.no_of_unmarried_brothers || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Country</td>
      <td>
        : <label class="p-ml-2">{{ profile.address_country || 'NA' }}</label>
      </td>
    </tr>
    <tr>
      <td>State</td>
      <td>
        : <label class="p-ml-2">{{ profile.address_state || 'NA' }}</label>
      </td>
    </tr>
  </table>
</template>
<script>
import { formFieldMixin } from '../../../mixins/formFieldMixin';
export default {
  mixins: [formFieldMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return;
      },
    },
  },
};
</script>
<style scoped>
.name-align {
  word-break: break-all;
}
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
