<template>
  <table class="table-style c-form">
    <tr>
      <td>Maximum Education</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(
            profile.education?.attributes?.highest_qualification
          ) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Occupation</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.occupation?.attributes?.job_type) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Monthly Take Home/Earnings</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile.occupation?.attributes?.salary.toLocaleString('en-IN') || 'NA'
        }}</label>
      </td>
    </tr>
  </table>
</template>
<script>
import { formFieldMixin } from '../../../mixins/formFieldMixin';
export default {
  mixins: [formFieldMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return;
      },
    },
  },
};
</script>
<style scoped>
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
