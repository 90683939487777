<template>
  <table class="table-style c-form">
    <tr>
      <td>Height (fts)</td>
      <td>
        :
        <label class="p-ml-2"
          >{{ profile?.physical_info?.attributes?.height || 'NA' }}
        </label>
      </td>
    </tr>
    <tr>
      <td>Weight (kgs)</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile?.physical_info?.attributes?.weight || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Differently Abled?</td>

      <td>
        :
        <label class="p-ml-2">{{
          temp[profile?.physical_info?.attributes?.differently_abled] || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Medical Condition</td>
      <td>
        :
        <label class="p-ml-2">{{
          profile?.physical_info?.attributes?.medical_conditions || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Marital Status</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.marital_status) || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Food Preference</td>
      <td>
        :
        <label class="p-ml-2">{{
          splitUnderscore(profile.diet) || 'NA'
        }}</label>
      </td>
    </tr>
  </table>
</template>
<script>
import { formFieldMixin } from '../../../mixins/formFieldMixin';
export default {
  mixins: [formFieldMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      temp: {
        true: 'Yes',
        false: 'No',
        null: 'NA',
      },
    };
  },
};
</script>
<style scoped>
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
