<template>
  <div
    v-if="!hideHeader"
    class="p-col-6 p-d-flex p-flex-column p-jc-between xxx"
  >
    <Skeleton
      v-for="index in rowCount"
      :key="`h-${index}`"
      height="1rem"
      :width="index % 2 === 0 ? '50%' : '40%'"
      class="p-mb-3"
      border-radius="16px"
    />
  </div>

  <div class="p-col-6 p-d-flex p-flex-column p-jc-between yyy">
    <Skeleton
      v-for="index in rowCount"
      :key="`t-${index}`"
      height="1rem"
      :width="index % 2 === 0 ? '70%' : '60%'"
      class="p-mb-3"
      border-radius="16px"
    />
  </div>
</template>

<script>
export default {
  props: {
    rowCount: {
      type: Number,
      default: 3,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
