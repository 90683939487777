<template>
  <div class="profile-card">
    <div class="p-grid p-nogutter card-container">
      <div class="p-col-12 p-sm-4 p-xl-4 p-d-flex p-flex-column p-jc-center">
        <div
          :class="`${
            authUser.slug !== profile.slug ? 'profile-alone' : ''
          } p-col-12 p-p-0 photo-container`"
        >
          <Galleria
            v-if="profile.profile_photo_url.length > 0"
            :value="profile.profile_photo_url"
            :num-visible="3"
            :circular="true"
          >
            <template #item="">
              <img
                :src="profile.display_photo_url"
                class="bg-image"
                @click="displayFullImage('dp')"
              />
              <div class="dp-image-container">
                <img
                  :src="profile.display_photo_url"
                  class="dp-image"
                  @click="displayFullImage('dp')"
                />
                <div
                  v-if="showApprovedBadgeDp(profile)"
                  class="image-badge-approved"
                >
                  Photo <br />
                  Approved
                </div>
                <div
                  v-if="showPendingBadgeDp(profile)"
                  class="image-badge-pending"
                >
                  Pending <br />
                  for Approval
                </div>
              </div>
            </template>
            <template #thumbnail="image">
              <div class="thumbnail-image-container">
                <img
                  :src="image.item.thumb_url"
                  class="dp-thumb-image"
                  @click="displayFullImage(image.item)"
                />
                <div
                  v-if="showApprovedBadge(image)"
                  class="thumb-image-badge-approved"
                >
                  Photo <br />
                  Approved
                </div>
                <div
                  v-if="showPendingBadge(image)"
                  class="thumb-image-badge-pending"
                >
                  Pending <br />
                  for Approval
                </div>
              </div>
            </template>
          </Galleria>
          <div v-else class="profile_img">
            <img :src="getProfileImg()" class="profile__img" />
          </div>
        </div>
        <div v-if="authUser.slug === profile.slug">
          <div
            v-if="
              authUser.slug === profile.slug &&
              profile.profile_photo_url.length < totalAllowedPhotosCount
            "
            class="upload-button p-col-12"
          >
            <p class="add-photos" @click="uploadDialog = true">Add Photos</p>
          </div>
          <div
            v-else
            v-tooltip.bottom="'You have already uploaded 15 photos.'"
            class="upload-button-disabled p-col-12"
          >
            <p class="add-photos">Add Photos</p>
          </div>
        </div>

        <Dialog
          v-model:visible="uploadDialog"
          :modal="true"
          :close-on-escape="true"
          :dismissable-mask="true"
          class="upload-dialog"
          :draggable="false"
        >
          <section>
            <image-upload
              name="demo[]"
              :custom-upload="true"
              :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
              :multiple="true"
              :file-limit="totalAllowedPhotosCount"
              :uploaded-count="profile.uploaded_photos_count"
              :preview-width="100"
              accept="image/png, image/jpeg"
              @select="imageCount"
              @remove="imageCountClear"
              @uploader="onUpload"
              @closeDialog="uploadDialog = false"
            >
              <template #empty>
                <div class="p-d-flex empty-text">
                  <div class="text-container">
                    <p class="actual-text">
                      Click on choose to attach files (or) Drag and drop files
                      here to upload. (Max 15 Images)
                    </p>
                  </div>
                </div>
              </template>
            </image-upload>
          </section>
        </Dialog>

        <Dialog
          v-model:visible="uploading"
          :show-header="false"
          :modal="true"
          :closable="false"
          :close-on-escape="false"
          class="uploading-dialog"
          :draggable="false"
        >
          <dot-loader class="align-self-center" />
          <p class="wait-text">Uploading, Please Wait</p>
        </Dialog>

        <Dialog
          v-model:visible="displayFull"
          :modal="true"
          :closable="true"
          :close-on-escape="true"
          :dismissable-mask="true"
          class="view-dialog"
          :draggable="false"
        >
          <div class="gallery-container">
            <Galleria
              v-model:visible="displayFull"
              v-model:activeIndex="activeIndex"
              :value="profile.profile_photo_url"
              :num-visible="totalAllowedPhotosCount"
              :circular="true"
              :full-screen="false"
              :show-item-navigators="true"
              class="full-image"
            >
              <template #item="image">
                <div class="image-container">
                  <img :src="image.item.url" class="image-itself" />
                  <div
                    v-if="showApprovedBadge(image)"
                    class="image-badge-approved"
                  >
                    Photo <br />
                    Approved
                  </div>
                  <div
                    v-if="showPendingBadge(image)"
                    class="image-badge-pending"
                  >
                    Pending <br />
                    for Approval
                  </div>
                </div>
              </template>
              <template #thumbnail="image">
                <div class="thumbnail-image-container">
                  <img :src="image.item.thumb_url" class="thumb-footer" />
                  <div
                    v-if="showApprovedBadge(image)"
                    class="thumb-image-badge-approved"
                  >
                    Photo <br />
                    Approved
                  </div>
                  <div
                    v-if="showPendingBadge(image)"
                    class="thumb-image-badge-pending"
                  >
                    Pending <br />
                    for Approval
                  </div>
                </div>
              </template>
            </Galleria>
            <div
              v-if="authUser.slug === profile.slug || canApprovePhoto"
              class="options"
            >
              <div
                v-if="authUser.slug === profile.slug"
                v-tooltip.top="
                  profile.profile_photo_url[activeIndex].is_approved
                    ? { disabled: true }
                    : 'Only approved images can be set as DP'
                "
              >
                <Button
                  :label="`${
                    profile.profile_photo_url[activeIndex].is_dp === true
                      ? 'Current DP'
                      : 'Set as DP'
                  }`"
                  :icon="`${
                    showIcons
                      ? settingDp
                        ? 'pi pi-spin pi-spinner'
                        : 'pi pi-id-card'
                      : ''
                  }`"
                  icon-pos="left"
                  :class="`${
                    profile.profile_photo_url[activeIndex].is_dp === true ||
                    settingDp ||
                    profile.profile_photo_url[activeIndex].is_approved === false
                      ? 'p-disabled'
                      : ''
                  }`"
                  @click="setDisplayPicture"
                />
              </div>
              <Button
                v-if="authUser.slug !== profile.slug && canApprovePhoto"
                :label="`${
                  profile.profile_photo_url[activeIndex].is_approved === false
                    ? 'Approve'
                    : 'Approved'
                }`"
                :icon="`${
                  approvingPic ? 'pi pi-spin pi-spinner' : 'pi pi-check'
                }`"
                icon-pos="left"
                :class="`${
                  profile.profile_photo_url[activeIndex].is_approved === true ||
                  approvingPic
                    ? 'p-disabled'
                    : ''
                }`"
                @click="approvePhoto"
              />
              <Button
                v-if="authUser.slug === profile.slug || canApprovePhoto"
                label="Delete"
                :icon="`${
                  deletingPic ? 'pi pi-spin pi-spinner' : 'pi pi-trash'
                }`"
                icon-pos="left"
                :class="`${deletingPic ? 'p-disabled' : ''} p-button-danger`"
                @click="deletePicture"
              />
            </div>
          </div>
        </Dialog>
      </div>

      <div class="p-col-12 p-sm-6 p-xl-6 info-sec c-form">
        <h3 class="c-tc-pd name-align p-text-capitalize">
          {{ profile.first_name }} {{ profile.last_name }}
        </h3>
        <p v-if="canApprovePhoto" class="c-tc-p-cfor">
          {{
            `${isRejected ? 'Rejected by: ' : 'Approved by: '}` +
            validatedBy(profile)
          }}
        </p>
        <p v-if="canApprovePhoto && isRejected" class="c-tc-p-cfor">
          Comment: {{ profile.comment }}
        </p>
        <p
          v-if="canApprovePhoto && profile.slug !== authUser.slug"
          class="c-tc-p-cfor"
        >
          {{ approvalFor(profile) }}
        </p>
        <p class="c-tc-p-cfor p-text-capitalize">
          profile created for {{ profile.created_for || 'entity' }}
        </p>
        <p>
          {{ height(profile.physical_info?.attributes?.height) }},
          {{ weight(profile.physical_info?.attributes?.weight) }}
        </p>
        <p>{{ profile.mother_tongue || 'NA' }}, {{ religionCaste(profile) }}</p>
        <p>
          {{ profile.address_state || 'NA' }},
          {{ profile.address_country || 'NA' }}
        </p>
        <p class="p-text-capitalize">
          {{ splitUnderscore(profile.occupation?.attributes?.job_type) }}
        </p>
        <div v-if="$route.name === 'myprofile'" class="p-d-flex p-jc-left">
          <span class="change-password-text" @click="showChangePasswordDialog()"
            >Change Password</span
          >
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="showPasswordChange"
      :hide="emptyPasswords()"
      :modal="true"
      header="Change Password"
      :draggable="false"
      :closable="true"
      :dismissable-mask="true"
      :close-on-escape="true"
    >
      <form
        class="p-d-flex p-flex-column reset-password-dialog p-px-4 p-pt-2"
        @submit.prevent="changePassword()"
      >
        <InputText
          v-model="password.old_password"
          type="password"
          placeholder="Old Password"
        />
        <div v-if="wrongOldPassword" class="p-error p-mb-1">
          <small>Please enter correct password</small>
        </div>
        <div
          :class="
            v$.password.old_password.required.$invalid && submitClicked
              ? ''
              : 'hide-password-err'
          "
          class="p-error p-mb-1"
        >
          <small> Old Password is required </small>
        </div>
        <InputText
          v-model="password.new_password"
          type="password"
          placeholder="New Password"
        />
        <div
          v-if="v$.password.new_password.required.$invalid && submitClicked"
          class="p-error"
        >
          <small> New Password is required </small>
        </div>
        <div
          v-if="
            v$.password.new_password.notSameAs.$invalid &&
            !v$.password.new_password.required.$invalid
          "
          class="p-error"
        >
          <small> New password cannot be old password </small>
        </div>
        <div
          :class="!password.new_password.length ? 'hide-password-err' : ''"
          class="p-error p-mb-1"
        >
          <small
            :class="
              v$.password.new_password.case.$invalid ? '' : 'hide-password-err'
            "
          >
            Must have at least 1 uppercase, 1 lowercase and 1 number
          </small>
          <small v-if="v$.password.new_password.minLength.$invalid">
            Password must contain minumum 8 characters
          </small>
        </div>
        <InputText
          v-model="password.new_password_confirm"
          type="password"
          placeholder="Confirm Password"
        />
        <div
          v-if="
            v$.password.new_password_confirm.required.$invalid && submitClicked
          "
          class="p-error"
        >
          <small> Password Confirmation is required </small>
        </div>
        <div
          :class="
            !password.new_password_confirm.length ? 'hide-password-err' : ''
          "
          class="p-error p-mb-1"
        >
          <small
            :class="
              v$.password.new_password_confirm.sameAs.$invalid
                ? ''
                : 'hide-password-err'
            "
          >
            Passwords do not match
          </small>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button
            :icon="changePasswordLoading ? 'fa fa-spinner fa-spin' : null"
            class="p-mt-2 p-px-5"
            label="Reset Password"
            type="submit"
            :disabled="v$.$error || changePasswordLoading"
          />
        </div>
      </form>
    </Dialog>
  </div>
</template>
<script>
import { formFieldMixin } from '@/mixins/formFieldMixin';
import { profileApprovalMixin } from '@/mixins/profileApprovalMixin.js';
import DotLoader from '@/utilities/DotLoader.vue';
import ImageUpload from '@/components/upload/ImageUpload.vue';
import { mapGetters } from 'vuex';
import useValidate from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
export default {
  components: {
    DotLoader,
    ImageUpload,
  },
  mixins: [formFieldMixin, profileApprovalMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['updatedProfileCard'],
  data() {
    return {
      v$: useValidate(),
      changePasswordLoading: false,
      wrongOldPassword: false,
      imagesArray: [],
      uploadDialog: false,
      displayFull: false,
      activeIndex: 0,
      uploading: false,
      totalAllowedPhotosCount: 15,
      settingDp: false,
      deletingPic: false,
      approvingPic: false,
      showIcons: true,
      showPasswordChange: false,
      password: {
        old_password: '',
        new_password: '',
        new_password_confirm: '',
      },
      submitClicked: false,
    };
  },
  validations() {
    return {
      password: {
        old_password: {
          required,
        },
        new_password: {
          required,
          minLength: minLength(8),
          case(val) {
            return this.passwordValidator(val);
          },
          notSameAs() {
            return this.password.old_password !== this.password.new_password;
          },
        },
        new_password_confirm: {
          required,
          sameAs: sameAs(this.password.new_password),
        },
      },
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canApprovePhoto', 'canApproveProfile']),
  },
  mounted() {
    this.v$.$validate();
    this.switchView();
    window.addEventListener('resize', this.switchView);
  },
  unmounted() {
    window.removeEventListener('resize', this.switchView);
  },
  methods: {
    emptyPasswords() {
      this.submitClicked = false;
      this.changePasswordLoading = false;
      this.wrongOldPassword = false;
      this.password.old_password = '';
      this.password.new_password = '';
      this.password.new_password_confirm = '';
    },
    showChangePasswordDialog() {
      this.showPasswordChange = true;
    },
    changePassword() {
      this.submitClicked = true;
      if (!this.v$.$error) {
        this.changePasswordLoading = true;
        this.$http
          .post(`users/${this.authUser.slug}/change_password`, {
            password: this.password,
          })
          .then(() => {
            this.wrongOldPassword = false;
            this.changePasswordLoading = false;
            this.$store.dispatch('logOut');
            this.$moshaToast('Password successfully changed', {
              type: 'success',
              showIcon: true,
              timeout: 3000,
            });
          })
          .catch((err) => {
            this.changePasswordLoading = false;
            err.response.status === 422
              ? (this.wrongOldPassword = true)
              : this.$moshaToast('Something went wrong', {
                  type: 'danger',
                  showIcon: true,
                  timeout: 3000,
                });
          });
      }
    },
    switchView() {
      this.showIcons = window.innerWidth > 390;
    },
    showApprovedBadge(image) {
      return (
        (this.authUser.slug === this.profile.slug || this.canApprovePhoto) &&
        image.item.is_approved
      );
    },
    showPendingBadge(image) {
      return (
        (this.authUser.slug === this.profile.slug || this.canApprovePhoto) &&
        !image.item.is_approved
      );
    },
    showApprovedBadgeDp(profile) {
      return (
        (this.authUser.slug === this.profile.slug || this.canApproveProfile) &&
        profile.profile_photo_url.filter(
          (item) => item.url === profile.display_photo_url
        )[0].is_approved
      );
    },
    showPendingBadgeDp(profile) {
      return (
        (this.authUser.slug === this.profile.slug || this.canApproveProfile) &&
        !profile.profile_photo_url.filter(
          (item) => item.url === profile.display_photo_url
        )[0].is_approved
      );
    },
    maxImages(profile) {
      return profile.profile_photo_url.length < this.totalAllowedPhotosCount
        ? false
        : true;
    },
    job(data) {
      return data
        ? data.occupation?.attributes?.job_type.replace('_', ' ')
        : 'NA';
    },
    religionCaste(data) {
      return (
        (data.social_class?.attributes?.religion?.charAt(0).toUpperCase() +
          data.social_class?.attributes?.religion?.slice(1) || 'NA') +
        '(' +
        (data.social_class?.attributes?.caste
          ? data.social_class?.attributes?.caste
          : 'NA') +
        ')'
      );
    },
    getProfileImg() {
      if (this.profile.profile_photo_url.length !== 0) {
        return this.profile.profile_photo_url;
      } else if (this.profile.gender === 'female') {
        return '/bride.png';
      } else {
        return '/groom.png';
      }
    },
    displayFullImage(image) {
      if (image === 'dp') {
        if (
          this.profile.profile_photo_url.filter((item) => item.is_dp === true)
            .length > 0
        ) {
          this.activeIndex = this.profile.profile_photo_url.indexOf(
            this.profile.profile_photo_url.filter(
              (item) => item.is_dp === true
            )[0]
          );
        } else {
          this.activeIndex = 0;
        }
      } else {
        this.activeIndex = this.profile.profile_photo_url.indexOf(image);
      }
      this.displayFull = true;
    },
    setDisplayPicture() {
      this.settingDp = true;
      let that = this;
      this.$http
        .patch(`users/${this.profile.slug}/set_as_dp`, {
          user: {
            photo_id: this.profile.profile_photo_url[this.activeIndex].id,
          },
        })
        .then((res) => {
          that.$store.dispatch('setAuthUser', res.data.data.attributes);
          that.$emit('updatedProfileCard', res);
          that.$moshaToast('Display picture set successfully', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
          that.settingDp = false;
        })
        .catch(() => {
          that.$moshaToast('Something went wrong, please try again.', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
          that.settingDp = false;
        });
    },
    deletePicture() {
      this.deletingPic = true;
      let that = this;
      this.$http
        .patch(`users/${this.profile.slug}/delete_photo`, {
          user: {
            photo_id: this.profile.profile_photo_url[this.activeIndex].id,
          },
        })
        .then((res) => {
          that.$emit('updatedProfileCard', res);
          res.data.data.attributes.profile_photo_url.length === 0
            ? (that.displayFull = false)
            : that.activeIndex ===
              res.data.data.attributes.profile_photo_url.length
            ? (that.activeIndex -= 1)
            : null;
          that.$moshaToast('Photo successfully deleted', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
          that.deletingPic = false;
        })
        .catch(() => {
          that.$moshaToast('Something went wrong, please try again.', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
          that.deletingPic = false;
        });
    },
    approvePhoto() {
      this.approvingPic = true;
      let that = this;
      this.$http
        .patch(`users/${this.profile.slug}/approve_photo`, {
          user: {
            photo_id: this.profile.profile_photo_url[this.activeIndex].id,
            approval_action: 'approve',
          },
        })
        .then((res) => {
          that.$emit('updatedProfileCard', res);
          that.profile.profile_photo_url.length - 1 === that.activeIndex
            ? (that.activeIndex = 0)
            : (that.activeIndex += 1);
          that.$moshaToast('Approved successfully', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
          that.approvingPic = false;
        })
        .catch(() => {
          that.$moshaToast('Something went wrong, please try again.', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
          that.approvingPic = false;
        });
    },
    validatedBy() {
      return this.profile.approved_by_name
        ? this.profile.approved_by_name +
            '(' +
            this.profile.approved_by_id +
            ')'
        : 'NA';
    },
    height(height) {
      if (!height) return 'NA';
      else
        return (
          Math.floor(height) + 'ft ' + Math.round((height % 1) * 10) + 'in'
        );
    },
    weight(weight) {
      if (!weight) return 'NA';
      else return weight + 'kg';
    },
    imageCount(event) {
      this.imagesArray = event.files;
      if (
        this.profile.profile_photo_url.length + this.imagesArray.length >
        this.totalAllowedPhotosCount
      ) {
        this.$moshaToast('Already Limit Breached. Max Images 15 only', {
          type: 'danger',
          showIcon: true,
          timeout: 2000,
        });
      }
    },
    imageCountClear(event) {
      this.imagesArray = event.files;
    },
    onUpload() {
      this.uploading = true;
      const formData = new FormData();
      let that = this;
      if (
        this.profile.profile_photo_url.length + this.imagesArray.length >
        this.totalAllowedPhotosCount
      ) {
        that.uploading = false;
      } else {
        for (const file of this.imagesArray) {
          formData.append('profile_photo[]', file, file.name);
        }
        let headers = {
          'Content-Type': 'multipart/form-data',
        };
        this.$http
          .patch(`/users/${this.authUser.slug}/upload_photo`, formData, {
            headers: headers,
          })
          .then((res) => {
            that.imagesArray = [];
            that.$emit('updatedProfileCard', res, true);
            that.uploadDialog = false;
            that.uploading = false;
            that.$moshaToast(
              'Photo(s) successfully uploaded, It will take 5-10 mins to reflect',
              {
                type: 'success',
                showIcon: true,
                timeout: 4000,
              }
            );
          })
          .catch((_) => {
            that.uploading = false;
            that.$moshaToast('Something went wrong, please try again.', {
              type: 'danger',
              showIcon: true,
              timeout: 2000,
            });
          });
      }
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>

<style lang="scss">
.profile-card {
  display: flex;
  justify-content: space-between;
  p:first-letter {
    text-transform: capitalize;
  }
  h3 {
    font-size: 22px;
  }
  .p-galleria-thumbnail-items-container {
    display: flex;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .name-align {
    word-break: break-word;
  }
  .p-galleria-thumbnail-container {
    justify-content: center;
    max-height: 4rem;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    .p-galleria-thumbnail-prev {
      color: grey;
    }
    .p-galleria-thumbnail-next {
      color: grey;
    }
  }
  .card-container {
    width: 100%;
    height: 100%;
    .upload-button {
      align-self: end;
      cursor: pointer;
      background-color: #e5e5e5;
      border-radius: 0 0 20px 20px;
      padding: 0.2rem;
    }
    .upload-button-disabled {
      align-self: end;
      cursor: pointer;
      background-color: #e5e5e5;
      border-radius: 0 0 20px 20px;
      color: rgb(70, 69, 69);
      padding: 0.2rem;
    }
    .p-galleria-content {
      border-top: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
    }
    .profile-alone {
      .p-galleria-content {
        border: 1px solid #e5e5e5;
        border-radius: 20px;
      }
      .p-galleria-thumbnail-container {
        border-radius: 20px;
      }
    }
    .p-galleria-item {
      border-radius: 10px;
    }
    .bg-image {
      position: absolute;
      filter: blur(5px);
      width: 100%;
      display: block;
      height: 12rem;
      object-fit: fit;
      border-radius: 20px 20px 0 0;
      z-index: 0;
    }
    .dp-image-container {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      border-radius: 3px;
      height: 12rem;
      .dp-image {
        max-width: 100%;
        display: block;
        max-height: 12rem;
        object-fit: contain;
        border-radius: 3px 3px 3px 3px;
        z-index: 1;
      }
      .image-badge-approved {
        position: absolute;
        top: 0;
        right: 0;
        width: 7rem;
        height: 7rem;
        transform: rotate(45deg);
        background: rgb(3, 172, 59);
        color: #f8f9fa;
        padding: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: -4rem;
        margin-right: -4rem;
        text-align: center;
        z-index: 2;
        font-size: 7px;
      }
      .image-badge-pending {
        position: absolute;
        top: 0;
        right: 0;
        width: 7rem;
        height: 7rem;
        transform: rotate(45deg);
        background: rgb(84, 88, 85);
        color: #f8f9fa;
        padding: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: -4rem;
        margin-right: -4rem;
        text-align: center;
        z-index: 2;
        font-size: 7px;
      }
    }

    .thumbnail-image-container {
      position: relative;
      overflow: hidden;
      .dp-thumb-image {
        height: 2rem;
        width: 100%;
        display: block;
      }
      .thumb-image-badge-approved {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        background: rgb(3, 172, 59);
        color: #f8f9fa;
        padding: 0rem;
        margin: 0rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        font-size: 2px;
        margin-top: -20px;
        margin-right: -20px;
      }
      .thumb-image-badge-pending {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        background: rgb(84, 88, 85);
        color: #f8f9fa;
        padding: 0rem;
        margin: 0rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        font-size: 2px;
        margin-top: -20px;
        margin-right: -20px;
      }
    }
    .p-galleria-thumbnail-prev {
      &:not(.p-disabled):hover {
        background: rgba(0, 0, 0, 0.048);
        color: rgb(253, 81, 109);
      }
    }
    .p-galleria-thumbnail-next {
      &:not(.p-disabled):hover {
        background: rgba(0, 0, 0, 0.048);
        color: rgb(253, 81, 109);
      }
    }
    .p-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: unset;
    }

    .p-galleria-thumbnail-item-content {
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0 !important;
      }
    }
    .add-photos {
      text-align: center;
    }
  }
  .info-sec {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  .upload-button {
    border-radius: 0 0 20px 20px;
    background-color: rgb(177, 176, 176);
    color: $primaryColor;
  }
  .profile_img {
    display: flex;
    justify-content: center;
    max-width: 100%;
    max-height: 15rem;
  }
  .profile__img {
    max-width: 100%;
    display: block;
    max-height: 15rem;
    object-fit: fit;
    margin-bottom: 1rem;
    border-radius: 3px;
    cursor: pointer;
  }
}
.upload-dialog {
  .p-dialog-header {
    display: none;
  }
  .p-dialog-content {
    order: 1;
    padding: 1rem 2rem 2rem 2rem;
    width: 980px;
    max-width: 90vw;
    .empty-text {
      align-items: center;
      .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .actual-text {
          background-color: white;
          display: flex;
          align-items: center;
          padding-left: 1rem;
          padding-right: 1rem;
          text-align: center;
        }
      }
    }
  }
  .p-fileupload-buttonbar {
    display: flex;
    .p-button:nth-child(3) {
      margin-left: auto;
    }
  }
}
.uploading-dialog {
  .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.45);
  }
  box-shadow: unset;
  .p-dialog-content {
    padding: unset;
    background-color: unset;
  }
  .p-dialog-header {
    display: none;
  }
  .wait-text {
    margin-top: 1rem;
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
  }
}
.view-dialog {
  display: flex;
  box-shadow: unset;
  max-width: 70%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .p-component {
    overflow: hidden;
  }
  .p-dialog-header {
    display: none;
  }
  .p-dialog-content {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    height: auto;
    padding: unset;
  }
  .gallery-container {
    width: 100%;
    height: 100%;
    .full-image {
      width: auto;
      height: 100%;
    }
    .p-galleria-item-wrapper {
      height: 70vh;
      .p-galleria-item {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .image-container {
      position: relative;
      overflow: hidden;
      .image-itself {
        display: block;
        max-width: 70vw;
        max-height: 70vh;
      }
      .image-badge-approved {
        position: absolute;
        top: 0;
        right: 0;
        width: 10rem;
        height: 10rem;
        transform: rotate(45deg);
        background: rgb(3, 172, 59);
        color: #f8f9fa;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: -5rem;
        margin-right: -5rem;
        text-align: center;
      }
      .image-badge-pending {
        position: absolute;
        top: 0;
        right: 0;
        width: 10rem;
        height: 10rem;
        transform: rotate(45deg);
        background: rgb(84, 88, 85);
        color: #f8f9fa;
        padding: 1rem;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: -5rem;
        margin-right: -5rem;
        text-align: center;
      }
    }
    .p-galleria-item-nav {
      top: unset;
      position: absolute;
      height: 100%;
      width: 7rem;
      z-index: 4;
      margin: 0 0;
      &:not(.p-disabled):hover {
        background: rgba(0, 0, 0, 0.048);
        color: #f8f9fa;
      }
    }
    .p-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: unset;
    }
    .p-galleria-thumbnail-container {
      justify-content: center;
      width: 70vw;
      margin-top: 2rem;
      .thumbnail-image-container {
        position: relative;
        overflow: hidden;
        .dp-thumb-image {
          width: 100%;
          display: block;
          max-height: 3rem;
        }
        .thumb-image-badge-approved {
          position: absolute;
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          transform: rotate(45deg);
          background: rgb(3, 172, 59);
          color: #f8f9fa;
          padding: 0rem;
          margin: 0rem;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          text-align: center;
          font-size: 2px;
          margin-top: -20px;
          margin-right: -20px;
        }
        .thumb-image-badge-pending {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          transform: rotate(45deg);
          background: rgb(84, 88, 85);
          color: #f8f9fa;
          padding: 0rem;
          margin: 0rem;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          text-align: center;
          font-size: 2px;
          margin-top: -20px;
          margin-right: -20px;
        }
      }
    }
    .thumb-footer {
      display: block;
      max-width: 70vw;
      max-height: 3rem;
    }
    .options {
      width: 100%;
      max-height: 20vh;
      background-color: rgba(0, 0, 0, 0.405);
      display: flex;
      align-items: center;
      color: white;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: space-between;
      @media screen and (max-width: 440px) {
        .p-button {
          padding: 5px;
        }
      }
      @media screen and (max-width: 330px) {
        .p-button {
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 2px;
          padding-right: 2px;
        }
      }
    }
    .p-galleria-thumbnail-items {
      transform: unset !important;
    }
  }
}
.p-dialog {
  .p-dialog-header {
    .p-dialog-header-icon {
      &:last-child {
        margin-right: 20px;
      }
      text-align: left;
      position: absolute;
      right: 0;
    }
  }
}
</style>

<style scoped lang="scss">
.change-password-text {
  color: #5651a7;
  &:hover {
    cursor: pointer;
  }
}
.hide-password-err {
  visibility: hidden;
}
.reset-password-dialog {
  width: 380px;
  input {
    width: 100%;
    font-size: 0.9rem;
    &::placeholder {
      color: #b8b8b8;
    }
  }
}
</style>
