<template>
  <table class="table-style c-form">
    <tr class="p-d-flex">
      <td>About</td>
      <td>
        : <label class="p-ml-2 about-align">{{ profile.about || 'NA' }}</label>
      </td>
    </tr>
    <tr>
      <td>First Name</td>
      <td>
        :
        <label class="p-ml-2 name-align">{{
          profile.first_name || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Last Name</td>
      <td>
        :
        <label class="p-ml-2 name-align">{{ profile.last_name || 'NA' }}</label>
      </td>
    </tr>
    <tr>
      <td>Age</td>
      <td>
        : <label class="p-ml-2">{{ profile.age || 'NA' }}</label>
      </td>
    </tr>
    <tr>
      <td>Mother Tongue</td>
      <td>
        : <label class="p-ml-2">{{ profile.mother_tongue || 'NA' }}</label>
      </td>
    </tr>
    <tr>
      <td>Created For</td>
      <td>
        : <label class="p-ml-2">{{ profile.created_for || 'NA' }}</label>
      </td>
    </tr>
  </table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function () {
    return {
      dob: null,
      basic: false,
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canViewMyProfile']),
  },
};
</script>
<style scoped lang="scss">
.connect-msg {
  color: $errorColor;
}
.about-align {
  word-break: break-word;
  text-transform: none;
}
.name-align {
  word-break: break-word;
}
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
