<template>
  <div v-if="isAdvanced" class="p-fileupload p-fileupload-advanced p-component">
    <div class="provitional-header p-d-flex p-jc-between p-ai-center">
      <p class="header-text p-d-flex p-ai-center p-m-0">
        Upload profile photos
      </p>
      <i class="pi pi-times p-p-1 cursor-pointer" @click="closeDialog"></i>
    </div>
    <div class="p-fileupload-buttonbar">
      <span
        v-ripple
        :class="advancedChooseButtonClass"
        tabindex="0"
        @click="choose"
        @keydown.enter="choose"
        @focus="onFocus"
        @blur="onBlur"
      >
        <input
          ref="fileInput"
          type="file"
          :multiple="multiple"
          :accept="accept"
          :disabled="chooseDisabled"
          @change="onFileSelect"
        />
        <span
          v-if="showIcons"
          class="p-button-icon p-button-icon-left pi pi-fw pi-plus"
        ></span>
        <span class="p-button-label">{{ chooseButtonLabel }}</span>
      </span>
      <FileUploadButton
        v-if="showUploadButton"
        :label="uploadButtonLabel"
        :icon="showIcons ? 'pi pi-upload' : ''"
        :disabled="uploadDisabled"
        @click="upload"
      />
      <FileUploadButton
        v-if="showCancelButton"
        :label="cancelButtonLabel"
        :icon="showIcons ? 'pi pi-times' : ''"
        :disabled="cancelDisabled"
        @click="clear"
      />
    </div>
    <div
      class="
        p-fileupload-buttonbar p-d-flex p-flex-wrap p-jc-between p-ai-center
      "
    >
      <div class="p-fileupload-buttonbar-card p-d-flex p-jc-evenly">
        <p class="p-my-0 p-mx-2">Maximum allowed: {{ fileLimit }}</p>
        <p class="p-my-0 p-mx-2">Already uploaded: {{ uploadedCount }}</p>
      </div>
      <div class="p-fileupload-buttonbar-card p-d-flex p-jc-evenly">
        <p class="p-my-0 p-mx-2">Can upload: {{ fileLimit - uploadedCount }}</p>
        <p class="p-my-0 p-mx-2">Currently selected: {{ files.length }}</p>
      </div>
    </div>
    <div
      ref="content"
      class="p-fileupload-content"
      @dragenter="onDragEnter"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <FileUploadMessage
        v-if="uploadedCount + files.length > fileLimit"
        severity="error"
        >You have chosen to upload more than {{ fileLimit }} images. Remove
        atleast:
        {{ uploadedCount + files.length - fileLimit }}</FileUploadMessage
      >
      <div v-if="hasFiles" class="p-fileupload-files">
        <div
          v-for="(file, index) of files"
          :key="file.name + file.type + file.size"
          class="fileupload-info-row"
        >
          <div>
            <img
              v-if="isImage(file)"
              role="presentation"
              :alt="file.name"
              :src="file.objectURL"
              :width="previewWidth"
            />
          </div>
          <div class="image-info">{{ file.name }}</div>
          <div class="image-info">{{ formatSize(file.size) }}</div>
          <div>
            <FileUploadButton
              type="button"
              icon="pi pi-times"
              @click="remove(index)"
            />
          </div>
        </div>
      </div>
      <div v-if="$slots.empty && !hasFiles" class="p-fileupload-empty">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Message from 'primevue/message';
import { DomHandler } from 'primevue/utils';
import Ripple from 'primevue/ripple';

export default {
  components: {
    FileUploadButton: Button,
    FileUploadMessage: Message,
  },
  directives: {
    ripple: Ripple,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: 'advanced',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: Boolean,
      default: false,
    },
    maxFileSize: {
      type: Number,
      default: null,
    },
    invalidFileSizeMessage: {
      type: String,
      default: '{0}: Invalid file size, file size should be smaller than {1}.',
    },
    fileLimit: {
      type: Number,
      default: null,
    },
    uploadedCount: {
      type: Number,
      default: 0,
    },
    invalidFileLimitMessage: {
      type: String,
      default: 'Maximum number of files exceeded, limit is {0} at most.',
    },
    withCredentials: {
      type: Boolean,
      default: false,
    },
    previewWidth: {
      type: Number,
      default: 50,
    },
    chooseLabel: {
      type: String,
      default: null,
    },
    uploadLabel: {
      type: String,
      default: null,
    },
    cancelLabel: {
      type: String,
      default: null,
    },
    customUpload: {
      type: Boolean,
      default: false,
    },
    showUploadButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'select',
    'uploader',
    'before-upload',
    'progress',
    'upload',
    'error',
    'before-send',
    'clear',
    'remove',
    'closeDialog',
  ],
  duplicateIEEvent: false,
  data() {
    return {
      uploadedFileCount: 0,
      files: [],
      messages: [],
      focused: false,
      progress: null,
      showIcons: true,
    };
  },
  computed: {
    isAdvanced() {
      return this.mode === 'advanced';
    },
    isBasic() {
      return this.mode === 'basic';
    },
    advancedChooseButtonClass() {
      return [
        'p-button p-component p-fileupload-choose',
        {
          'p-disabled':
            this.disabled ||
            this.files.length + this.uploadedCount >= this.fileLimit,
          'p-focus': this.focused,
        },
      ];
    },
    hasFiles() {
      return this.files && this.files.length > 0;
    },
    chooseDisabled() {
      return (
        this.disabled ||
        (this.fileLimit &&
          this.fileLimit <= this.files.length + this.uploadedFileCount) ||
        this.files.length + this.uploadedCount >= this.fileLimit
      );
    },
    uploadDisabled() {
      return (
        this.disabled ||
        !this.hasFiles ||
        this.files.length + this.uploadedCount > this.fileLimit
      );
    },
    cancelDisabled() {
      return this.disabled || !this.hasFiles;
    },
    chooseButtonLabel() {
      return this.chooseLabel || this.$primevue.config.locale.choose;
    },
    uploadButtonLabel() {
      return this.uploadLabel || this.$primevue.config.locale.upload;
    },
    cancelButtonLabel() {
      return this.cancelLabel || this.$primevue.config.locale.cancel;
    },
  },
  mounted() {
    this.switchView();
    window.addEventListener('resize', this.switchView);
  },
  unmounted() {
    window.removeEventListener('resize', this.switchView);
  },
  methods: {
    switchView() {
      this.showIcons = window.innerWidth > 420;
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    onFileSelect(event) {
      if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
        this.duplicateIEEvent = false;
        return;
      }
      this.messages = [];
      this.files = this.files || [];
      let files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;
      for (let file of files) {
        if (!this.isFileSelected(file)) {
          if (this.validate(file)) {
            if (this.isImage(file)) {
              file.objectURL = window.URL.createObjectURL(file);
            }
            this.files.push(file);
          }
        }
      }
      let allFiles = this.files;
      this.files = [];
      let invalidFiles = [];
      allFiles.forEach((img) => {
        ['image/jpeg', 'image/jpg', 'image/png'].indexOf(img.type) !== -1
          ? this.files.push(img)
          : invalidFiles.push(img);
      });
      if (invalidFiles.length) {
        this.$moshaToast('Only JPEG and PNG are allowed', {
          type: 'danger',
          showIcon: true,
          timeout: 2000,
        });
      }
      this.$emit('select', { originalEvent: event, files: this.files });

      if (this.fileLimit) {
        this.checkFileLimit();
      }

      if (this.auto && this.hasFiles && !this.isFileLimitExceeded()) {
        this.upload();
      }

      if (event.type !== 'drop' && this.isIE11()) {
        this.clearIEInput();
      } else {
        this.clearInputElement();
      }
    },
    choose() {
      this.$refs.fileInput.click();
    },
    upload() {
      if (this.customUpload) {
        if (this.fileLimit) {
          this.uploadedFileCount += this.files.length;
        }

        this.$emit('uploader', { files: this.files });
      } else {
        let xhr = new XMLHttpRequest();
        let formData = new FormData();

        this.$emit('before-upload', {
          xhr: xhr,
          formData: formData,
        });

        for (let file of this.files) {
          formData.append(this.name, file, file.name);
        }

        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            this.progress = Math.round((event.loaded * 100) / event.total);
          }

          this.$emit('progress', {
            originalEvent: event,
            progress: this.progress,
          });
        });

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            this.progress = 0;

            if (xhr.status >= 200 && xhr.status < 300) {
              if (this.fileLimit) {
                this.uploadedFileCount += this.files.length;
              }

              this.$emit('upload', {
                xhr: xhr,
                files: this.files,
              });
            } else {
              this.$emit('error', {
                xhr: xhr,
                files: this.files,
              });
            }

            this.clear();
          }
        };

        xhr.open('POST', this.url, true);

        this.$emit('before-send', {
          xhr: xhr,
          formData: formData,
        });

        xhr.withCredentials = this.withCredentials;

        xhr.send(formData);
      }
    },
    clear() {
      this.files = [];
      this.messages = null;
      this.$emit('clear');

      if (this.isAdvanced) {
        this.clearInputElement();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    isFileSelected(file) {
      if (this.files && this.files.length) {
        for (let sFile of this.files) {
          if (
            sFile.name + sFile.type + sFile.size ===
            file.name + file.type + file.size
          )
            return true;
        }
      }

      return false;
    },
    isIE11() {
      return !!window['MSInputMethodContext'] && !!document['documentMode'];
    },
    validate(file) {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.messages.push(
          this.invalidFileSizeMessage
            .replace('{0}', file.name)
            .replace('{1}', this.formatSize(this.maxFileSize))
        );
        return false;
      }

      return true;
    },
    onDragEnter(event) {
      if (!this.disabled) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragOver() {
      if (!this.disabled) {
        DomHandler.addClass(this.$refs.content, 'p-fileupload-highlight');
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragLeave() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
      }
    },
    onDrop() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
        event.stopPropagation();
        event.preventDefault();

        const files = event.dataTransfer
          ? event.dataTransfer.files
          : event.target.files;
        const allowDrop = this.multiple || (files && files.length === 1);

        if (allowDrop) {
          this.onFileSelect(event);
        }
      }
    },
    onBasicUploaderClick() {
      if (this.hasFiles) this.upload();
      else this.$refs.fileInput.click();
    },
    remove(index) {
      this.clearInputElement();
      let removedFile = this.files.splice(index, 1)[0];
      this.files = [...this.files];
      this.$emit('remove', {
        file: removedFile,
        files: this.files,
      });
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    clearInputElement() {
      this.$refs.fileInput.value = '';
    },
    clearIEInput() {
      if (this.$refs.fileInput) {
        this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
        this.$refs.fileInput.value = '';
      }
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return '0 B';
      }
      let k = 1000,
        dm = 3,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    isFileLimitExceeded() {
      if (
        this.fileLimit &&
        this.fileLimit <= this.files.length + this.uploadedFileCount &&
        this.focused
      ) {
        this.focused = false;
      }

      return (
        this.fileLimit &&
        this.fileLimit < this.files.length + this.uploadedFileCount
      );
    },
    checkFileLimit() {
      if (this.isFileLimitExceeded()) {
        this.messages.push(
          this.invalidFileLimitMessage.replace('{0}', this.fileLimit.toString())
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fileupload-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .image-info {
    margin: 10px;
  }
  @media screen and (max-width: 420px) {
    .image-info {
      display: none;
    }
  }
}
.provitional-header {
  margin-bottom: 1rem;
  .header-text {
    font-size: 18px;
    font-weight: 400;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
.p-fileupload-content {
  position: relative;
  .p-progressbar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.p-button.p-fileupload-choose {
  position: relative;
  overflow: hidden;
  input[type='file'] {
    display: none;
  }
}
.p-fileupload-choose.p-fileupload-choose-selected {
  input[type='file'] {
    display: none;
  }
}
.p-fluid {
  .p-fileupload {
    .p-button {
      width: auto;
    }
  }
}
.p-message.p-message-error {
  .p-message-close {
    display: none;
  }
}
</style>
