<template>
  <table class="table-style c-form">
    <tr>
      <td>Name</td>
      <td>
        :
        <label v-if="showDetails" class="p-ml-2 name-align">******</label>
        <label v-else class="p-ml-2 name-align">
          {{ profile.contact_name || 'NA' }}</label
        >
      </td>
    </tr>
    <tr>
      <td>Relation</td>
      <td>
        :
        <label v-if="showDetails" class="p-ml-2 name-align">******</label>
        <label v-else class="p-ml-2 name-align">{{
          profile.contact_relation || 'NA'
        }}</label>
      </td>
    </tr>
    <tr v-if="authUser.is_admin">
      <td>Email</td>
      <td>
        :
        <label v-if="showDetails" class="p-ml-2 name-align">******</label>
        <label v-else class="p-ml-2 p-text-lowercase name-align">{{
          profile.email || 'NA'
        }}</label>
      </td>
    </tr>
    <tr>
      <td>Mobile No</td>
      <td>
        :
        <label v-if="showDetails" class="p-ml-2 name-align">******</label>
        <label
          v-else
          :class="`${
            interestStatus !== 'accepted' &&
            profile.id !== authUser.id &&
            canViewMyProfile
              ? 'connect-msg'
              : ''
          } p-ml-2`"
          >{{ profile.primary_contact_number || 'NA' }}</label
        >
      </td>
    </tr>
  </table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showDetails: {
      type: Boolean,
      default() {
        return null;
      },
    },
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    interestStatus: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      dob: null,
      basic: false,
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canViewMyProfile']),
  },
};
</script>
<style scoped lang="scss">
.connect-msg {
  color: $errorColor;
}
.name-align {
  word-break: break-all;
}
Button {
  height: 38px;
}

.table-style {
  width: 80%;
}

.header-style {
  display: flex;
  justify-content: space-between;
}
h3 {
  width: 80%;
}
td {
  text-transform: capitalize;
  display: inline-flex;
  width: 50%;
  padding: 7px;
}
</style>
