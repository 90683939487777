<template>
  <div>
    <Button
      class="del-prof-btn"
      icon="pi pi-trash"
      :label="`${
        $route.name === 'myprofile'
          ? 'Delete My Profile'
          : 'Delete This Profile'
      }`"
      @click="toggleDelProfDialog()"
    />
    <Dialog
      v-model:visible="deleteMyProfile"
      header="Confirmation"
      :modal="true"
      :closable="true"
      :close-on-escape="true"
      :dismissable-mask="true"
    >
      <div class="confirmation-content p-mx-4 p-d-flex p-ai-center">
        <i class="pi pi-exclamation-triangle p-mr-3 triangle-icon" />
        <p>
          Once you delete
          <span> {{ authUser.is_admin ? 'this' : 'your' }} </span> profile there
          is no going back. Do you want to continue?
        </p>
      </div>
      <template #footer>
        <Button
          label="Yes"
          class="p-button-primary p-button-outlined p-mr-3 confirm-btn"
          @click="deleteProfile()"
        />
        <Button
          label="No"
          class="p-button-primary confirm-btn"
          autofocus
          @click="toggleDelProfDialog()"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { redirectMixin } from '@/mixins/redirectMixin';
export default {
  mixins: [redirectMixin],
  data() {
    return { deleteMyProfile: false };
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  methods: {
    ...mapActions(['logOut']),
    toggleDelProfDialog() {
      this.deleteMyProfile = !this.deleteMyProfile;
    },
    hasHistory() {
      return window.history.length > 2;
    },
    deleteProfile() {
      let slug = this.authUser.is_admin
        ? this.$route.params.slugId
        : this.authUser.slug;
      let that = this;
      this.$http
        .delete(`/users/${slug}`)
        .then(() => {
          that.authUser.is_admin
            ? that.hasHistory()
              ? that.$router.go(-1)
              : that.goToDashboard()
            : that.logOut();
          that.$moshaToast('Profile Deleted Successfully', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
        })
        .catch(() => {
          that.$moshaToast('Something went wrong', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
  },
};
</script>
<style scoped>
.triangle-icon {
  font-size: 2rem;
}
</style>
